import clsx from "clsx";
import Link from "next/link";

import { Button } from "@chef/components";
import { useTrack } from "@chef/state-management/hooks";

interface CTAComponentProps {
  value?: {
    align?: "left" | "center" | "right";
    desktopAlign?: "left" | "center" | "right";
    text?: string;
    href?: string;
    trackingName?: string;
    style?: "primary" | "outlined";
    width?: "auto" | "full";
    desktopWidth?: "auto" | "full";
  };
}

interface InnerComponentProps {
  href: string;
  onClick: () => void;
  style?: "primary" | "outlined";
  width?: "auto" | "full";
  desktopWidth?: "auto" | "full";
  isInternal: boolean;
  children: React.ReactNode;
}

const InnerComponent = ({
  isInternal,
  href,
  onClick,
  width,
  desktopWidth,
  style,
  children,
}: InnerComponentProps) => {
  if (isInternal) {
    return (
      <Link
        href={href}
        className={clsx(
          width === "full" && "w-full",
          desktopWidth === "auto" && "md:w-auto",
          desktopWidth === "full" && "md:w-full",
          "no-underline",
        )}
        passHref
      >
        <Button
          onClick={onClick}
          primary={style === "primary"}
          outlined={style === "outlined"}
          full={width === "full"}
        >
          {children}
        </Button>
      </Link>
    );
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(
        width === "full" && "w-full",
        desktopWidth === "auto" && "md:w-auto",
        desktopWidth === "full" && "md:w-full",
        "no-underline",
      )}
    >
      <Button
        onClick={onClick}
        primary={style === "primary"}
        outlined={style === "outlined"}
        full={width === "full"}
      >
        {children}
      </Button>
    </a>
  );
};

export const CTAComponent = ({ value }: CTAComponentProps) => {
  const track = useTrack();

  const handleButtonClicked = () => {
    if (!value || !value.trackingName) {
      return;
    }

    track("buttonClicked", {
      affiliation: "Frontend process",
      button_colour: value.style || "default",
      button_text: value.text || "unset",
      button_source: "sanity",
      button_destination: value.href,
      button_placement: value.trackingName,
      button_action: "click",
    });
  };

  if (!value) {
    return null;
  }

  const isInternal = value.href?.startsWith("/");

  return (
    <div
      className={clsx(
        "flex my-4 md:my-6",
        value.align === "center" && "justify-center",
        value.align === "right" && "justify-end",
        value.align === "left" && "justify-start",
        value.desktopAlign === "center" && "md:justify-center",
        value.desktopAlign === "right" && "md:justify-end",
        value.desktopAlign === "left" && "md:justify-start",
      )}
    >
      <InnerComponent
        onClick={handleButtonClicked}
        href={value.href || "#"}
        isInternal={isInternal || false}
        style={value.style}
        width={value.width}
        desktopWidth={value.desktopWidth}
      >
        {value.text}
      </InnerComponent>
    </div>
  );
};
