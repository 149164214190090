import { PRODUCT_TYPE_IDS } from "@chef/constants";
import { getWeek, getYear, nextDeliveryWeekDateObj } from "@chef/helpers";
import { Mealcards } from "@chef/smart-components";
import { usePickAndMixQuery } from "@chef/state-management";
import { isNotEmptyArray } from "@chef/utils/array";
import { isEqualStrings } from "@chef/utils/equal";

interface IRecipesStub {
  useNextDeliveryWeek: boolean;
  week: number;
  year: number;
}

interface IRecipesFromTagCollection extends IRecipesStub {
  _type: "recipesFromTagCollection";
  tags: string[];
}

interface ISpecificRecipes extends IRecipesStub {
  _type: "specificRecipes";
  recipes: string[];
}

interface RecipesComponentProps {
  value?: IRecipesFromTagCollection | ISpecificRecipes;
}

export const RecipesComponent = ({ value }: RecipesComponentProps) => {
  const nextDeliveryDateObj = nextDeliveryWeekDateObj();

  const week = value?.useNextDeliveryWeek
    ? getWeek(nextDeliveryDateObj)
    : value?.week;

  const year = value?.useNextDeliveryWeek
    ? getYear(nextDeliveryDateObj)
    : value?.year;

  let hasData = false;

  if (value?._type === "recipesFromTagCollection") {
    hasData = isNotEmptyArray(value?.tags) || false;
  } else {
    hasData = isNotEmptyArray(value?.recipes) || false;
  }

  const shouldSkip = !week || !year || !value || !hasData;

  const { data: pickAndMixQuery } = usePickAndMixQuery(
    {
      week: week!,
      year: year!,
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
    },
    { skip: shouldSkip },
  );

  if (!pickAndMixQuery || !value) {
    return null;
  }

  let productIds: string[] = [];

  if (value._type === "recipesFromTagCollection") {
    for (const t of value.tags) {
      const p = pickAndMixQuery.pickAndMix.find(
        // eslint-disable-next-line no-loop-func
        (i) => {
          const alreadyExists = productIds.includes(i.productId);

          if (alreadyExists) {
            return false;
          }

          const hasTag = i.recipes[0]?.taxonomies.some((j) =>
            isEqualStrings(j.taxonomyId.toString(), t),
          );

          return hasTag;
        },
      );

      if (!p) {
        continue;
      }

      productIds.push(p?.productId);
    }
  } else {
    productIds = value.recipes;
  }

  return (
    <div className="flex justify-center not-prose">
      <Mealcards
        productIds={productIds}
        week={week!}
        year={year!}
        portions={4}
        meals={productIds.length}
        isDelivered={false}
        className="!items-stretch"
        grid={{
          cols: {
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
          },
          rows: {
            sm: 2,
            md: 1,
            lg: 1,
            xl: 1,
          },
        }}
        view="conversion"
      />
    </div>
  );
};
