import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";

import { getClient } from "../utils";
import { IContent } from "../types";

export interface IArticle extends SanityDocument {
  _type: "article";
  slug: Slug;
  content: IContent;
  relatedArticles?: IArticle[];
}

export const ARTICLES_PER_PAGE = 12;

export const articleQuery = groq`*[_type == "article" && slug.current == $slug][0]{
  ...,

  relatedArticles[]->{
    ...,

    content->{
      _type,
      seo {
        image {
          asset->
        }
      }
    }
  },

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const articlesQuery = groq`*[_type == "article"] | order(_createdAt desc) {
  ...,

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const getArticleData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IArticle>(articleQuery, { slug });
};

export const getArticlesCountData = async () => {
  const client = getClient();

  const articlesCountQuery = groq`count(*[_type == "article"])`;

  return await client.fetch<number>(articlesCountQuery);
};

export const getLightArticlesData = async () => {
  const client = getClient();

  const lightArticlesQuery = groq`*[_type == "article"] {
    _id,
    content->{
     title,
     body
    }
  }`;

  return await client.fetch<IArticle[]>(lightArticlesQuery);
};

export const getSearchedArticlesData = async (ids: string[]) => {
  const client = getClient();

  const searchedArticlesQuery = groq`*[_type == "article" && _id in $ids] | order(_createdAt desc){
    ...,

    content->{
      ...,
      seo {
        ...,

        image {
          asset->
        }
      }
    }
  }`;

  return await client.fetch<IArticle[]>(searchedArticlesQuery, { ids });
};

export const getPaginatedArticlesData = async (startIndex = 1) => {
  const client = getClient();

  const pagenatedArticlesQuery = groq`*[_type == "article" ] | order(_createdAt desc)[${startIndex}...${
    startIndex + ARTICLES_PER_PAGE
  }] {
    ...,

    content->{
      ...,
      seo {
        ...,

        image {
          asset->
        }
      }
    }
  }`;

  return await client.fetch<IArticle[]>(pagenatedArticlesQuery, { startIndex });
};
